<template>
    <div class="card">
        <div class="card-body">
            <template v-if="!loading">
                <b-row v-for="setting in settings" :key="setting.id">
                    <b-col md="12">
                        <b-checkbox v-model="setting.value">{{ ucfirst(setting.name) }}</b-checkbox>
                    </b-col>
                </b-row>
                <div v-if="!loadingHistory && history.length" class="row">
                    <div class="col-12">
                        <pre>{{ historyLog }}</pre>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="spinner-border text-info" role="status"></div>
            </template>
        </div>
        <b-card-footer>
            <div class="row">
                <div class="col-12 text-right">
                    <b-button variant="success" @click="save" :disabled="disabled">Сохранить</b-button>
                </div>
            </div>
        </b-card-footer>
    </div>
</template>

<script>
import moment              from "moment"
import {SETTINGS_ENDPOINT} from "@utils/endpoints"

export default {
    name: 'Index',
    data() {
        return {
            settings: [],
            history: [],
            saving: false,
            loading: false,
            loadingHistory: false,
        }
    },
    computed: {
        disabled() {
            return this.saving || this.loading;
        },
        historyLog() {
            return this.history.map(({created_at, createdBy, message}) => {
                const {fullname, login} = createdBy
                return [
                    moment(created_at).format("DD.MM.YYYY HH:mm:ss"),
                    '[' + (fullname || login) + ']',
                    this.ucfirst(message)
                ].join(" ")
            }).join("\r\n")
        }
    },
    methods: {
        ucfirst(value) {
            if (!value) {
                return;
            }

            return value[0].toUpperCase() + value.slice(1);
        },
        save() {
            this.saving = true;

            this.$http.put(SETTINGS_ENDPOINT, this.settings).then(() => {
                this.$bvToast.toast('Успешно сохранено', {variant: 'success'});
            }).catch(() => {
                this.$bvToast.toast('Не удалось сохранить настройки', {variant: 'danger'});
            }).finally(() => {
                this.saving = false
                this.loadSettings();
                this.loadHistory();
            });
        },
        loadSettings() {
            this.loading = true

            this.$http
                .get(SETTINGS_ENDPOINT)
                .then(response => response.data.data.data)
                .then(settings => {
                    this.settings = settings
                })
                .catch(() => {
                    this.$bvToast.toast('Не удалось загрузить настройки', {variant: 'danger'});
                })
                .finally(() => {
                    this.loading = false
                })
        },
        loadHistory() {
            this.loadingHistory = true

            this.$http
                .get(SETTINGS_ENDPOINT + '/history', {
                    params: {
                        with: [
                            'createdBy'
                        ],
                        sort: 'id',
                        sortDirection: 'desc',
                        limit: 20
                    }
                })
                .then(response => response.data.data.data)
                .then(history => {
                    this.history = history
                })
                .catch(() => {
                    this.$bvToast.toast('Не удалось загрузить историю', {variant: 'danger'});
                })
                .finally(() => {
                    this.loadingHistory = false
                })
        }
    },
    mounted() {
        this.loadSettings()
        this.loadHistory()
    },
}
</script>
